import React from "react";
import Seo from "../components/seo/Seo";
import Layout from "../components/shared/Layout";

const CellLineTemplate = ({ pageContext: { cellLine } }) => {
  return (
    <Layout>
      <Seo title={cellLine.name} />
      <section className="flex flex-col px-4 py-12 xl:px-0">
        <div className="container grid px-4 mx-auto lg:grid-cols-2">
          <h1 className="text-4xl font-bold text-slate-900 xl:text-6xl">
            {cellLine.name}
          </h1>
          <p className="ml-1 font-semibold xl:text-2xl text-slate-900 lg:row-start-2">
            {cellLine.description}
          </p>
        </div>
        <div className="flex flex-col w-full mx-auto overflow-hidden xl:container bg-zinc-100 rounded-3xl">
          <div className="p-4">
            <h2 className="text-4xl font-bold tracking-tight text-slate-900">
              Origin
            </h2>
            <h3>
              {cellLine.cellSourceRegion}, {cellLine.cellSourceCountry}
            </h3>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CellLineTemplate;
